import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "クラウド診療支援システム「CLINICS」の患者タッチポイントを強化する PRM プロジェクトはどう作り上げたのか",
  "date": "2022-09-30T13:23:26.000Z",
  "slug": "entry/2022/09/30/222326",
  "tags": [],
  "hero": "./2022_09_30.png",
  "heroAlt": "Title"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの新居です。クラウド診療支援システム CLINICS が 8 月から大幅に`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/news/58"
      }}>{`アップデート`}</a>{`して、より患者と医療機関が密接に繋がるようになりました。`}</p>
    <p>{`今回のアップデートで中心になっている機能領域として `}<strong parentName="p">{`PRM(Patient Relationship Management)`}</strong>{` というものがあります。 この PRM が一体どういったものなのか、プロジェクトはどのように進んでいったのかを関係者にインタビューしていきました。`}</p>
    <p>{`メドレーで大規模なプロジェクト開発をどのように行なっているかの一端をお見せできればと考えています!`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`宍戸さん`}</h2>
    <p>{`医療プラットフォーム第一本部 プロダクト開発室第二グループに所属。クラウド診療支援システム `}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{` の開発責任者。前職は株式会社サイバーエージェントでサーバサイドエンジニアとして従事。2017 年メドレー入社。`}</p>
    <h2>{`酒井さん`}</h2>
    <p>{`医療プラットフォーム第一本部 プロダクト開発室第二グループに所属。クラウド診療支援システム CLINICS の UI/UX 全般を担当。本プロジェクトのリード。前職は受託開発会社で様々なサイトやシステムのデザインに従事。2019 年メドレー入社。`}</p>
    <h2>{`田中さん`}</h2>
    <p>{`医療プラットフォーム CTO。医療プラットフォームの開発統括をしながら、自身は患者統合基盤の開発を担当。前職までは SIer や IT コンサルタントを経て、株式会社サイバーエージェントでサーバサイドエンジニアとして従事。2016 年メドレー入社。`}</p>
    <h2>{`来田さん`}</h2>
    <p>{`前職まではクリニックで精神科医として従事。CLINCS オンライン診療の立ち上げ時から関わる。現在は CLINICS のディレクションを中心として事業部と開発の架け橋となっている。`}</p>
    <h1>{`PRM とはどういうものなのか?`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{` : では今回中心となった PRM とはいったい何なのかというところから解説していただいて良いですか? 一般的にはそこまで知られてない言葉なのではないかと思うのですが…。`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{`: 元々 CRM(Customer Relationship Management)というマーケティングなどの文脈の用語があります。これは「自社サービスや商品を使っている顧客」と「サービスや商品を提供している企業」との関係性を管理することを指しますが、それをもとに適切なアクションをおこなうことで最終的には利益向上を目指すものと考えられます。`}<strong parentName="p">{`PRM は CRM と同様に医療機関と患者さんの関係性を管理することで必要なサービスを提供する`}</strong>{`ためのもの、と考えています。`}</p>
    <p>{`CLINICS で基幹システムである電子カルテなどではなく、`}<strong parentName="p">{`オンライン診療・予約・問診など患者接点となる機能をターゲットにしてそれらを強化・改善`}</strong>{`していったのが、CLINICS の今回の PRM プロジェクトになります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eab9acb42adb7a84d8018a55799110c2/eea4a/note0908_010.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACA//EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAFe+S6yDFkP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAMCIgESMv/aAAgBAQABBQKGbTTbcWO4P//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwERf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPwE1f//EABgQAAIDAAAAAAAAAAAAAAAAAAABAyBB/9oACAEBAAY/AkKTVX//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExQVFxkcH/2gAIAQEAAT8h7ARLcJpZIbPZRwYP/9oADAMBAAIAAwAAABAwP//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxA4mf/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxB6C2//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFhcZGx/9oACAEBAAE/ELMmKQCZ4OA3v3DbfMJV0WCuo0qUy+Q08T//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0908 010",
            "title": "note0908 010",
            "src": "/static/eab9acb42adb7a84d8018a55799110c2/e5166/note0908_010.jpg",
            "srcSet": ["/static/eab9acb42adb7a84d8018a55799110c2/f93b5/note0908_010.jpg 300w", "/static/eab9acb42adb7a84d8018a55799110c2/b4294/note0908_010.jpg 600w", "/static/eab9acb42adb7a84d8018a55799110c2/e5166/note0908_010.jpg 1200w", "/static/eab9acb42adb7a84d8018a55799110c2/eea4a/note0908_010.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`CLINICS 開発責任者 宍戸さん`}</em></p>
    <p><strong parentName="p">{`新居`}</strong>{` : 確かに CRM は最近良く聞く言葉でしたが、PRM は患者と医療サービスを使う医療機関との関係を深めるためのものなんですね。`}</p>
    <h1>{`PRM プロジェクトの始まり`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/227dfe0d94edb162493c354daa74b353/eea4a/note0908_009.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFuLZbhJMB//8QAHBAAAQMFAAAAAAAAAAAAAAAAAQACAxMiMTIz/9oACAEBAAEFAtS+Y1bwpeITs//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAcEAACAQUBAAAAAAAAAAAAAAAAARICEBMhcVH/2gAIAQEABj8CxvU/DHGTN1Iq4Llv/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUZH/2gAIAQEAAT8hRDYmuqg0KXEZ4fjmUMTEIXAGErv2z//aAAwDAQACAAMAAAAQ6z//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAwEBPxBZL//EABYRAQEBAAAAAAAAAAAAAAAAAAABIf/aAAgBAgEBPxCRh//EAB4QAQACAgIDAQAAAAAAAAAAAAEAESExYaFBUXGB/9oACAEBAAE/ENgmI2Ntt473M7IlGp+dxaXWivTGuc3OcxGE+Q/JBYUxDSvkeWf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0908 009",
            "title": "note0908 009",
            "src": "/static/227dfe0d94edb162493c354daa74b353/e5166/note0908_009.jpg",
            "srcSet": ["/static/227dfe0d94edb162493c354daa74b353/f93b5/note0908_009.jpg 300w", "/static/227dfe0d94edb162493c354daa74b353/b4294/note0908_009.jpg 600w", "/static/227dfe0d94edb162493c354daa74b353/e5166/note0908_009.jpg 1200w", "/static/227dfe0d94edb162493c354daa74b353/eea4a/note0908_009.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`CLINICS に感じていた課題とは`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : このプロジェクトは半年ほどかけていると聞きましたが、「PRM をやっていこう」となったのが半年前からだったんでしょうか?`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{` : 元々 CLINICS はオンライン診療のシステムとして 2016 年からサービスを提供してきました。2018 年からは CLINICS カルテとして電子カルテシステムも提供を開始しましたが、そのタイミング以降はオンライン診療部分に関してはそこまで大きくアップデートはしてこなかったんです。`}</p>
    <p>{`現在はクラウド診療支援システムとして販売していますが、これまでは電子カルテなど基幹部分の強化がメインになっていたからです。しかし、数年開発・運用してきた中で、本来医療機関の診察のメインである対面診療の予約については課題が積み残されていたり、昨今の状況から来る`}<strong parentName="p">{`オンライン診療のニーズなどを始めとして、患者接点を強化していく必要がある`}</strong>{`ということになり、今回取り組むことになりました。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : PRM というと本当にここ最近の潮流なのですが、`}<strong parentName="p">{`CLINICS はもともと「患者とつながる」をコンセプト`}</strong>{`としており、今回の機能強化などもこのコンセプトに基づき実施しています。その上で、患者とのタッチポイントで抜けていた部分や強化したほうが良い部分をより作り込んでいったという形です。`}</p>
    <p>{`例えば、CLINICS は元々オンライン診療のシステムとして始まっており、対面診療予約に関して使いづらさを感じるケースがあったため、対面診療予約をより行いやすくするよう改修を行うなどです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dcf9f6861daebcdb109b5ba6692703b4/eea4a/note0908_011.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUCBP/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAd0OUxOSwX//xAAbEAABBAMAAAAAAAAAAAAAAAADAAECMiIjM//aAAgBAQABBQIw3mhvqJLMlQcpW//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwERf//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAECAQE/Aar/xAAaEAEBAAIDAAAAAAAAAAAAAAABAAIhIzFx/9oACAEBAAY/Au9DssSedn2ym//EABwQAQADAAIDAAAAAAAAAAAAAAEAETEhUWGh8P/aAAgBAQABPyHqD2FmgCtgN4c4OT6fESLfU96f/9oADAMBAAIAAwAAABArH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQMBAT8QgTOX/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBNpVen/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFBUWGx8P/aAAgBAQABPxAnGKDyIafu4gKA4tVFVMDtg0B4maMuj7gTIS4jxkTy9vs//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0908 011",
            "title": "note0908 011",
            "src": "/static/dcf9f6861daebcdb109b5ba6692703b4/e5166/note0908_011.jpg",
            "srcSet": ["/static/dcf9f6861daebcdb109b5ba6692703b4/f93b5/note0908_011.jpg 300w", "/static/dcf9f6861daebcdb109b5ba6692703b4/b4294/note0908_011.jpg 600w", "/static/dcf9f6861daebcdb109b5ba6692703b4/e5166/note0908_011.jpg 1200w", "/static/dcf9f6861daebcdb109b5ba6692703b4/eea4a/note0908_011.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`CLINICS デザイナー 酒井さん`}</em></p>
    <p><strong parentName="p">{`新居`}</strong>{` : 元々あったものをさらに患者とのタッチポイントを強化するために、改修・改善していったんですね。`}</p>
    <h1>{`プロジェクトの背景`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba06a43f3de9fd2bc1bcb79d26aa2b51/eea4a/note0908_007.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAH/2gAMAwEAAhADEAAAAddkV0OsRzL/xAAaEAADAAMBAAAAAAAAAAAAAAABAhEAAzEi/9oACAEBAAEFAlsX0p13G4OMTf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGtf//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBIxMoGR/9oACAEBAAY/ApS0ZJ0tXs//xAAaEAADAQEBAQAAAAAAAAAAAAAAASERMUFh/9oACAEBAAE/Ias7NeMZ7LfzA2q/GczmISx//9oADAMBAAIAAwAAABBA7//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBOkP/EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QaE4f/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8QT5A1Whd8y0OiwDfu4sMEaHVzCHUdgOByOABRg/Cf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0908 007",
            "title": "note0908 007",
            "src": "/static/ba06a43f3de9fd2bc1bcb79d26aa2b51/e5166/note0908_007.jpg",
            "srcSet": ["/static/ba06a43f3de9fd2bc1bcb79d26aa2b51/f93b5/note0908_007.jpg 300w", "/static/ba06a43f3de9fd2bc1bcb79d26aa2b51/b4294/note0908_007.jpg 600w", "/static/ba06a43f3de9fd2bc1bcb79d26aa2b51/e5166/note0908_007.jpg 1200w", "/static/ba06a43f3de9fd2bc1bcb79d26aa2b51/eea4a/note0908_007.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`どのくらいの規模のプロジェクトだったのか`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : 半年のプロジェクトということですが、規模としてはどの位の大きさだったんでしょうか?`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : 私が経験したプロジェクトの中でも関わる部署など考えると一番大きかったと思います。開発はもちろんですが、CLINICS チームの`}<strong parentName="p">{`セールス・カスタマーサクセス・マーケティング、広報もなのでほぼ CLINICS の全部署と関わっていました`}</strong>{`。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{` : 他にも、サービスとしては患者統合基盤・ 患者向けアプリ・ Dentis が関係しています。`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{` : QA チームにもしっかり入ってもらって QA を行なったりしてもらったので、本当に`}<strong parentName="p">{`医療プラットフォームのほぼ全てのチームと関わって開発していった`}</strong>{`感じです。最終的にプロダクトの見せ方や売り方なども含めて調整していったので事業部とも密接に関わって作り上げていきました。`}</p>
    <h2>{`新しい PRM 機能をどうやって、プロダクトに融合させたのか`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : そこまで大きい規模のプロジェクトだということで、PRM の各種機能をどうやって上手くプロダクトに落としこむのか、実際にはどのようにみなさん推進されていったんでしょう。`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{` : プロジェクトの推進という意味では、最初は来田さんや( Dentis の運営を行なっている)プロダクト戦略室の平山さんと`}<strong parentName="p">{`スモールスタートで意見を出し合いながらモックを作っていき、まずはビジュアル面から機能のイメージなどを刷り合わせ`}</strong>{`ていくのに、酒井さんに手を動かしてもらいながら進めていました。`}</p>
    <p>{`その過程でこのプロジェクトにおける重要な意思決定がありました。CLINICS ではログイン不要のシンプルな予約機能を以前より提供していました。この機能は患者アプリとは元々違うドメインで運営していたものでしたが、今回のタイミングでこの両者のアプリケーションを患者アプリと同じドメインに統合して運営していこうという決定をしました。`}</p>
    <p>{`患者アプリは CLINICS チームとは別チームで運営しているという背景もあり、チーム間の連携が格段に増え 、考えなければいけない事柄も多くなります。プロジェクトとして関係者が多くなってきたので、`}<strong parentName="p">{`今までの考え方を 1 度リセットしてプロジェクトの進め方を新しく模索する必要がありました`}</strong>{`。`}</p>
    <p>{`この点がかなり自分の中では大変でした。来田さんも大変だったと思います。こうした全体像を描いたり、全体スケジュールを決めたりというところでは、田中さんにアドバイスをもらいながらプロジェクトを進めていきました。`}</p>
    <h1>{`プロジェクトを推進するために必要だったこと`}</h1>
    <h2>{`難しい意思決定をどのようにしていた?`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : 途中から一気にプロジェクトがスケールアップしたということなんですね。今回のプロジェクトは色々な部署も関わるし、たくさんの機能や改修が入っています。開発側と事業部側との意識合わせが、かなり必要だったんではないかと思うのですが、その辺はいかがだったでしょうか。`}</p>
    <p><strong parentName="p">{`来田`}</strong>{` : 例えば、今まで CLINICS では対面診療予約の際に患者のクレジットカード登録を必須としていたのですが、今回の改修でクレジットカード登録を不要に変更できるように対応を行いました。この対応は、CLINICS オンライン診療の初期から念願の機能だったりします。自分は事業部長である田中大介さんと今年の年明けくらいから話をして、メンバーに「よし、このプロジェクトをやっていこう」という話を持っていくようにしていました。やっぱり、大変なプロジェクトだというのはあったのでスタートを切れる状態に徐々にしていった感じです。`}</p>
    <p>{`プロジェクトが佳境に入ってからは自分は各部署との調整がメインの業務でした。開発側と事業側とか CLINICS と Dentis だとかそういった立場の違いから来る要求の違いを納得できる仕様に落とし込む作業です。やっぱり、それぞれの立場でベストを尽くそうとみんな考えているんですが、全部を取り入れることはできないので。私が調整するときには`}<strong parentName="p">{`それぞれ反対の立場の人たちの考えをきちんと伝えるようにということをしながら、仕様を決めていく`}</strong>{`ことを念頭に置いていました。`}</p>
    <p>{`具体的な調整の例としては、開発する機能とセールスする際のプランの刷り合わせだったりですね。「機能としてはこうしたほうが使いやすいよね」というだけだと、セールス側としては「どこをウリにして売っていくのかというのが見えてこない」という話になったんですね。これはどちらの言い分も一理あるものなんですが、こういうところを時間をかけて`}<strong parentName="p">{`双方納得行くように決めていきました`}</strong>{`。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/efc58777883fff9762d3a47625811d7f/eea4a/note0908_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAa8bwpUiy5//xAAbEAACAgMBAAAAAAAAAAAAAAABAwACBBETMv/aAAgBAQABBQKzAoZC+lZUbjoPP//EABYRAQEBAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPwFYXP/EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPwEKzTf/xAAaEAADAAMBAAAAAAAAAAAAAAAAARECEEFx/9oACAEBAAY/ArlZTnulRCP/xAAbEAEAAgIDAAAAAAAAAAAAAAABABFRoSExcf/aAAgBAQABPyGkywS7CmKQqjyACBOe4tZqT//aAAwDAQACAAMAAAAQZx//xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFB/9oACAEDAQE/EKJqX//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKAf/8QAGhABAQADAQEAAAAAAAAAAAAAAREAMYEhQf/aAAgBAQABPxBzOAJGE3gmEgrUN+cxAhoCLvWJzT4FMaCs+O43gz//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0908 002",
            "title": "note0908 002",
            "src": "/static/efc58777883fff9762d3a47625811d7f/e5166/note0908_002.jpg",
            "srcSet": ["/static/efc58777883fff9762d3a47625811d7f/f93b5/note0908_002.jpg 300w", "/static/efc58777883fff9762d3a47625811d7f/b4294/note0908_002.jpg 600w", "/static/efc58777883fff9762d3a47625811d7f/e5166/note0908_002.jpg 1200w", "/static/efc58777883fff9762d3a47625811d7f/eea4a/note0908_002.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`CLINICS ディレクター・医師 来田さん`}</em></p>
    <p><strong parentName="p">{`酒井`}</strong>{` : この例でやったことは、まず現在のプランと機能を全部書き出して、組み合わせを整理するということでした。これをベースにして話を刷り合わせていったんですが、文字ベースだと人間分かった気になるんですが、実はそこまでピンと来ない。ですので、`}<strong parentName="p">{`LP のモックを作ってこれを見た顧客が導入したくなるようなウリになっているのかをすぐに判断できる状態`}</strong>{`にしました。`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{` : こうしてちゃんと機能を解いていって細かい部分も含めて、時には CLINICS のコンセプトに立ち返って事業部・開発双方で時間をかけて認識を合わせることができたのは、大変でしたが良かったですね。`}</p>
    <h2>{`リリースまでのプロセスで顧客の声を反映`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : やはりここまでのプロジェクトだと最初の段階でちゃんとリリースまでを見据えた意識合わせをチーム内で行なっていたんですね。開発が進行していって、リリースするまではどのようにプロジェクトは進んでいったんでしょう。`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{` : リリースをする直前も、単純に仕様や機能について文書で説明しても理解するのは難しいので、酒井さんにディレクションをしてもらいながら、開発環境を使って QA チームやカスタマーサポートチームと一緒に認識合わせやリリースに向けた準備を行なっていきました。`}</p>
    <p><strong parentName="p">{`来田`}</strong>{` : あとは関係が構築できている`}<strong parentName="p">{`顧客に実際機能を見てもらって反応を伺う`}</strong>{`ということもしていきました。色々な使い方をしている顧客 10 件くらいにモックをベースにヒアリングをかけていきました。価格や導入時期なども含めた話を聞いていって、そこをすぐにプロジェクトにフィードバックする体制を構築していました。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : 実際に顧客の声をこうして聞けるというのは本当にプロダクトにとってありがたいことでした。特にこういう使い勝手などに直接関わる部分では、こうしたフィードバックの有無で完成度が違ってきます。`}<strong parentName="p">{`機能の正式リリース前から機能評価に協力していただける医療機関がいるのは、カスタマーサポートの皆さんが顧客と良好な関係を築けているおかげ`}</strong>{`だと思っています。こういった点はメドレーの強みだと思います。`}</p>
    <h2>{`プロジェクトで大切にしたこと`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : 開発段階からユーザーの声を吸い上げていけるのは、本当に良いですね。プロダクトの改善スピードなんかも段違いに早くなりそうです。他に大切にしていた部分などありますか?`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : 今回の機能開発においては、プロジェクトの責任者として、開発以外のメンバーともしっかりコミュニケーションし合意形成を図ることを意識してプロジェクトを推進しました。`}</p>
    <p>{`先ほどの話にも出ましたが 1 つの機能を実装するとなっても、実際の医療現場での使い勝手や、メドレーでのサポート、売り方など色々な視点で考えないといけないプロジェクトでした。そのため、そういったところをカスタマーサクセスのメンバー含む 10 人ほどと毎週 1 つ 1 つ納得がいくように詰めていくという作業をしていったりしました。`}<strong parentName="p">{`来田さんのように現役の医師の意見、顧客の意見なども取り入れられますし、カスタマーサポートには医療事務経験者の方もいらっしゃるので、多方面の視点を総合的に取り入れられるコミュニケーション`}</strong>{`を心がけていました。`}</p>
    <p>{`手段はテキストや Figma でのデザイン、実際の実装を見せるなど適切な手段を選んでコミュニケーションコストを低くするようにして進行していました。`}<strong parentName="p">{`実際の機能実装にあたってはエンジニアも自身で考えて細かいボールを拾ったりしながら、実装`}</strong>{`していってもらいました。この辺りはこのプロジェクトだからというよりも、メドレーの開発のベースになっている部分なので良いですよね。`}</p>
    <h2>{`メンバーのイメージ合わせがプロジェクト成功の鍵`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{` : 先ほどもお話がありましたが、プロジェクト途中の意思決定で関わるプロダクトが多くなったのでスイッチングが大変だったということでしたが、実際にそのタイミングになったときに全体のシステム設計なんかも変わったりしたんでしょうか。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{` : 私はプロジェクトの途中から入ったんですが、そのタイミングで、宍戸さんや来田さんがちょうどシステム設計を考え直していたり、プロジェクト計画の引き直しをしているところでした。そこでまずは全体像を把握したいと考えてドキュメントなどを見せてもらったんです。各機能の細かい要件は分かったのですが、このプロジェクト全体としての各システムの責務や依存関係などがいまいち分かりづらく、メンバー間の認識も若干ズレが発生しているように感じました。なのでまずは`}<strong parentName="p">{`メンバーの認識を合わせ、各チームが何をするべきかを明確化するために、各システムの責務など全体感の整理整頓をして「見える化」`}</strong>{`することから始めました。`}</p>
    <p>{`このプロジェクトに限らずですが、プロジェクトとして抽象度の高い段階でのドキュメントは、文章のみの記載だと実は関係者にしっかりと伝わっていなかったということが多々あります。各自が頭の中でイメージした図に差異が発生している状態です。自分はそういう際に良く言っているのが`}<strong parentName="p">{`「大枠で良いので、まずは整理結果を図にしてみんなのイメージを合わせる`}</strong>{`」ということです。こうして認識を合わせることで各チームが進めやすくなり後戻りリスクも軽減されるので、この段階でしっかりとイメージをすりあわせる事がとても重要だと考えています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5ae0602a8de8e976a5eb8437d1a5cab5/eea4a/note0908_004.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAZtVKiGtFDL/xAAbEAABBQEBAAAAAAAAAAAAAAACAAEDBBESM//aAAgBAQABBQK2PSrmJMrvtC+Ta+//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAHBAAAgICAwAAAAAAAAAAAAAAABECIgEQMVGB/9oACAEBAAY/Ao2RXLWvCC7OT//EABkQAQEBAQEBAAAAAAAAAAAAAAERADEhkf/aAAgBAQABPyHqkVpcxBQCau5GJQTxzNnr7v/aAAwDAQACAAMAAAAQTz//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EFkIf//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAECAQE/EKP/xAAeEAACAgEFAQAAAAAAAAAAAAABEQAhQVFhgZGh8P/aAAgBAQABPxDEkigHTfih7g7IJ/OMsDtwzcpa5MNRUiZKnN+eqf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0908 004",
            "title": "note0908 004",
            "src": "/static/5ae0602a8de8e976a5eb8437d1a5cab5/e5166/note0908_004.jpg",
            "srcSet": ["/static/5ae0602a8de8e976a5eb8437d1a5cab5/f93b5/note0908_004.jpg 300w", "/static/5ae0602a8de8e976a5eb8437d1a5cab5/b4294/note0908_004.jpg 600w", "/static/5ae0602a8de8e976a5eb8437d1a5cab5/e5166/note0908_004.jpg 1200w", "/static/5ae0602a8de8e976a5eb8437d1a5cab5/eea4a/note0908_004.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`医療プラットフォーム CTO 田中さん`}</em></p>
    <p><strong parentName="p">{`新居`}</strong>{` : たしかにテキストで仕様なんかを書きながら説明しても、実は認識に齟齬があった…という経験があります。その後はどのようなプロセスでプロジェクト推進したんですか?`}</p>
    <p><strong parentName="p">{`田中`}</strong>{` : その後は各プロダクトにて実装予定の機能で、プロダクト間で共通化できそうな機能を共通サービスとして切り出すかどうかをみんなと考えていきました。結果として大きな機能では「ビデオ通話」部分を共通化するという意思決定をしました。他にも「問診票の提供」機能が候補としてあがっていましたが、共通化せずに各プロダクトの責務としてそれぞれ実装するという結論となりました。同じような機能に見えても、医科と歯科という業務ドメイン毎のコンテキスト差異を十分に検討した上での判断でした。`}<strong parentName="p">{`その差異がプロダクト機能としての強みとなり、安易な共通化はプロダクト成長の足枷となるリスクも存在`}</strong>{`するためです。この判断も先ほどの全体の依存関係などが整理されたからこそ、意思決定できるものでした。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : 機能実装時に実感しましたが、やはり医科と歯科では細かい部分の要件などが異なる部分が多かったので、この段階で共通化の判断ができたのは大変助かりました。`}</p>
    <h1>{`PRM プロジェクトの大きな成果`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{` : 最後になりますが、このプロジェクトが終わっての成果はどのように考えていますか?`}</p>
    <p><strong parentName="p">{`宍戸`}</strong>{` : 冒頭に話した CLINICS の課題に関してはある程度、解決ができたと思います。`}<strong parentName="p">{`医療機関の業務に沿った形でプロダクトが進化`}</strong>{`できた点はよかったと思います。特に問診周りなどは発熱外来などニーズの多様化に即して使い勝手が良くなったと思っています。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : 他にもビデオ通話部分など「オンライン診療」を提供するプロダクトとして「対面診療」だけではなく、もっと有用なプロダクトにできたかと思っています。`}</p>
    <p><strong parentName="p">{`来田`}</strong>{` : まだリリースしたばかりですが、初月の販売実績も期待していたよりも良く、そうした意味でも`}<strong parentName="p">{`マーケットフィットした機能を出せたなという手応え`}</strong>{`が実感としてもありますね。`}</p>
    <p><strong parentName="p">{`田中`}</strong>{` : 予約やオンライン診療もドメインを統一した結果、会員登録数が増えていますので、そうした点も成果と言えます。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{` : あとは直接の成果というわけではないのですが、今回のプロジェクトの副産物として`}</p>
    <p>{`CLINICS の機能を整理整頓していった結果、`}<strong parentName="p">{`改めて「一気通貫で医療機関の業務をサポートできる」というトータルでの機能提供と各機能のシームレスな連携は CLINICS の強み`}</strong>{`だ、というのが再確認できたのは大きいです。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{` : これからのさらなる CLINICS の発展に期待できますね!本日は長い時間お話ありがとうございました!`}</p>
    <h1>{`おわりに`}</h1>
    <p>{`メドレーとしてもかなり大規模なプロジェクトだった今回の PRM プロジェクトでしたが、みなさんのお話を聞いて各部署・各プロダクトで綿密に連携しながらプロジェクトを成功させようとしている様子が感じられるインタビューでした。`}</p>
    <p>{`特にプロジェクト進行の話は非常に参考になりました。皆さんのご参考になる部分があれば幸いです。`}</p>
    <p>{`こうしたプロジェクトで自分の力を発揮したい!と思った方はぜひお気軽に下記からご応募ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      